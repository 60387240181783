<template>
    <LiefengContent>
        <template #title>{{ menuCodeObj.menuName || "优质机构名单" }}</template>
        <template #toolsbarRight>
            <Button :disabled="loading" style="margin: 0" :type="!orgOnlineStatus ? 'primary' : ''" @click="checkStatus('')">全部</Button>
            <Button :disabled="loading" style="margin: 0" :type="orgOnlineStatus == 1 ? 'primary' : ''" @click="checkStatus(1)">市级示范社区</Button>
            <Button :disabled="loading" style="margin: 0" :type="orgOnlineStatus == 2 ? 'primary' : ''" @click="checkStatus(2)">区级示范社区</Button>
            <Button :disabled="loading" style="margin: 0" :type="orgOnlineStatus == 3 ? 'primary' : ''" @click="checkStatus(3)">街镇级示范社区</Button>

            <Button :disabled="loading" :type="level == 3 ? 'info' : ''" @click="changeLevel(3)" style="margin: 0 0 0 50px">按区</Button>
            <Button :disabled="loading" :type="level == 4 ? 'info' : ''" @click="changeLevel(4)" style="margin: 0">按街道</Button>
            <Button :disabled="loading" :type="level == 5 ? 'info' : ''" @click="changeLevel(5)" style="margin: 0">按社区</Button>

            <Button :disabled="loading" type="error" style="margin: 0 0 0 50px" @click="Export">导出报表</Button>

        </template>
        <template #contentArea>
           
            <Form :label-colon="true" :inline="true" class="search" style="position: relative; margin: 5px 0; display: flex; justify-content: space-between">
                <div style="display:flex;width:100%;">
                    <LiefengCascaderCity
                        v-if="showCascader"
                        :disabled="disabled"
                        :orgLevel="level"
                        @changeCasader="changeCasader"
                        :resetNum="resetNum"
                        :orgCode="4401"
                        :width="200"
                        :isClear="true"
                    ></LiefengCascaderCity>

                    
                <div style="display: flex; justify-content: space-between;margin-left:auto">
                    <span style="margin-top: 7px;margin-left:10px ">更新时间：{{ nowTime1 + " 00:00:00" || "" }}</span>
                </div>
                </div>
            </Form>

            <LiefengTable
                ref="tab"
                :tableData="tableData"
                :talbeColumns="talbeColumns"
                :loading="loading"
                :fixTable="true"
                :hidePage="isHidePage"
                :curPage="page"
                :total="total"
                :page-size="pageSize"
                :pagesizeOpts="[50, 1000, 2000, 3000]"
                @hadlePageSize="hadlePageSize"
                :showIndex="false"
            ></LiefengTable>

            <!-- 优质机构数量 -->
            <LiefengModal :title="nameUrl" width="700px" height="70vh" :value="blankStatus" @input="changeBlankStatus">
                <template v-slot:title>
                    <p style="font-size: 18px; font-weight: 700">优质机构数量：{{ clumnNum }}</p>
                    <p>更新时间：{{ nowTime2 + " 00:00:00" || "" }}</p>
                </template>
                <template v-slot:contentarea>
                    <Button type="primary" style="margin-left: 87%; margin-bottom: 10px" @click="exportColumn">导出报表</Button>
                    <LiefengTable :showIndex="false" ref="tabs" :tableData="bTableData" :talbeColumns="bTalbeColumns" :hidePage="true"></LiefengTable>
                </template>
                <template v-slot:toolsbar>
                    <Button style="margin: 0 5px 0 13px" type="primary" @click="blankStatus = false">确定</Button>
                </template>
            </LiefengModal>
        </template>
    </LiefengContent>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3"
import LiefengTable from "@/components/LiefengTable"
import LiefengModal from "@/components/LiefengModal"
import LiefengCascaderCity from "./children/LiefengCascaderCity"
export default {
    components: {
        LiefengContent,
        LiefengTable,
        LiefengModal,
        LiefengCascaderCity,
    },
    data() {
        return {
            orgOnlineStatus:1, // 切换的状态
            level: 5, // 切换的级别
            columnName: 0, // 栏目数
            nowTime1: "", // 当前时间
            resetNum: 1, // 是否清空当前社区选择

            tableData: [],
            talbeColumns: [],
            loading: false,
            menuCodeObj: {},

            cascaderList: [],

            // 栏目点击参数

            blankStatus: false,
            bTableData: [],
            bTalbeColumns: [
                {
                    title: "优质机构名称",
                    key: "columnName",
                    minWidth: 400,
                    align: "center",
                },
            ],
            bLoading: false,
            nameUrl: "",
            clumnNum: 0,
            nowTime2: "",

            onlineType: "",

            disabled: false, // 是否禁用选择器
            isHidePage: false,

            page: 1,
            pageSize: 50,
            total: 0,

            allTableData: [], //暂存全部信息
            showCascader: true,
        }
    },
    async created() {
        this.nowTime1 = this.$core.formatDate(new Date(new Date().getTime()), "yyyy-MM-dd")
        await this.getByMenuCode()
        await this.getList()
    },
    methods: {
        // 改变分页
        hadlePageSize(val) {
            this.page = val.page
            this.pageSize = val.pageSize
            this.tableData = []
            if(val.page == 1){
                this.tableData = JSON.parse(JSON.stringify(this.allTableData)).slice(0, this.page * this.pageSize)
            }else{
                this.tableData = JSON.parse(JSON.stringify(this.allTableData)).slice((this.page * this.pageSize) - (this.pageSize), this.page * this.pageSize)
            }
        },
        // 改变示范社区东西
        changeSelect(val) {
            if (val == undefined) return
            this.getList()
        },
        // 改变选择社区组件
        changeCasader(val) {
            this.cascaderList = val
            this.onlineType = ""
            if (!val.length) {
                this.resetNum++
                this.getList()
            }
            if (this.level == "3") {
                if (val.length == 1) this.getList()
            } else if (this.level == "4") {
                if (val.length == 2) this.getList()
            } else if (this.level == "5") {
                if (val.length == 3) this.getList()
            }
        },
        async checkStatus(val) {
            if (this.orgOnlineStatus == val) return
            this.orgOnlineStatus = val
            await this.getList()
        },
        async changeLevel(val) {
            if (this.level == val) {
                return
            }
            this.level = val
            this.showCascader = false
            this.$nextTick(() => {
                this.showCascader = true
            })
            this.isHidePage = true
            if (this.level == "5") this.isHidePage = false
            this.resetNum++
            this.cascaderList = []
            await this.getList()
        },

        async changeLevel2(val) {
            if (this.level == val) {
                return
            }
            this.level = val
            this.showCascader = false
            this.$nextTick(() => {
                this.showCascader = true
            })
            this.isHidePage = true
            if (this.level == "5") this.isHidePage = false
            this.resetNum++
            await this.getList()
        },

        //获取列表
        async getList(val) {
            let arr1 = []
            let arr2 = []
            this.disabled = true
            if (this.orgOnlineStatus != 2 && this.level != 5) {
                if (this.level == 3) {
                    arr1 = [
                        {
                            title: "区",
                            key: "zoneName",
                            align: "center",
                            width: 200,
                            render: (h, params) => {
                                return h(
                                    "div",
                                    {
                                        style: {
                                            color: this.level == 3 ? "blue" : "#000",
                                            cursor: this.level == 3 ? "pointer" : "auto",
                                            textDecoration: this.level == 3 ? "underline" : "none",
                                        },
                                        on: {
                                            click: async () => {
                                                if (this.level == 3) {
                                                    this.cascaderList = [params.row.zoneCode]
                                                    this.changeLevel2(4)
                                                }
                                            },
                                        },
                                    },
                                    params.row.zoneName
                                )
                            },
                        },
                    ]
                } else if (this.level == 4) {
                    arr1 = [
                        {
                            title: "区",
                            key: "zoneName",
                            align: "center",
                            width: 200,
                        },
                        {
                            title: "街道",
                            key: "streetName",
                            align: "center",
                            width: 200,
                            render: (h, params) => {
                                return h(
                                    "div",
                                    {
                                        style: {
                                            color: this.level == 4 ? "blue" : "#000",
                                            cursor: this.level == 4 ? "pointer" : "auto",
                                            textDecoration: this.level == 4 ? "underline" : "none",
                                        },
                                        on: {
                                            click: async () => {
                                                if (this.level == 4) {
                                                    this.cascaderList = [params.row.zoneCode, params.row.streetCode]
                                                    this.changeLevel2(5)
                                                }
                                            },
                                        },
                                    },
                                    params.row.streetName
                                )
                            },
                        },
                    ]
                }
                arr2 = [
                    {
                        title: "辖区单位数",
                        key: "unitNum",
                        align: "center",
                        minWidth: 200,
                    },
                    {
                        title: "优质机构数",
                        key: "unitGoodNum",
                        align: "center",
                        minWidth: 200,
                    },
                ]
            } else if (this.orgOnlineStatus == 2 && this.level != 5) {
                if (this.level == 3) {
                    arr1 = [
                        {
                            title: "区",
                            key: "zoneName",
                            align: "center",
                            width: 200,
                            render: (h, params) => {
                                return h(
                                    "div",
                                    {
                                        style: {
                                            color: this.level == 3 ? "blue" : "#000",
                                            cursor: this.level == 3 ? "pointer" : "auto",
                                            textDecoration: this.level == 3 ? "underline" : "none",
                                        },
                                        on: {
                                            click: async () => {
                                                if (this.level == 3) {
                                                    this.cascaderList = [params.row.zoneCode]
                                                    this.changeLevel2(4)
                                                }
                                            },
                                        },
                                    },
                                    params.row.zoneName
                                )
                            },
                        },
                    ]
                } else if (this.level == 4) {
                    arr1 = [
                        {
                            title: "区",
                            key: "zoneName",
                            align: "center",
                            width: 200,
                        },
                        {
                            title: "街道",
                            key: "streetName",
                            align: "center",
                            width: 200,
                            render: (h, params) => {
                                return h(
                                    "div",
                                    {
                                        style: {
                                            color: this.level == 4 ? "blue" : "#000",
                                            cursor: this.level == 4 ? "pointer" : "auto",
                                            textDecoration: this.level == 4 ? "underline" : "none",
                                        },
                                        on: {
                                            click: async () => {
                                                if (this.level == 4) {
                                                    this.cascaderList = [params.row.zoneCode, params.row.streetCode]
                                                    this.changeLevel2(5)
                                                }
                                            },
                                        },
                                    },
                                    params.row.streetName
                                )
                            },
                        },
                    ]
                }
                arr2 = [
                    {
                        title: "试用社区数",
                        key: "communityNum",
                        align: "center",
                        minWidth: 200,
                    },
                    {
                        title: "空白栏目试用社区数",
                        key: "blankColumnCommunityNum",
                        align: "center",
                        minWidth: 200,
                        render: (h, params) => {
                            return h(
                                "div",
                                {
                                    style: {
                                        color: this.level == 3 ? "blue" : "#000",
                                        cursor: this.level == 3 ? "pointer" : "auto",
                                        textDecoration: this.level == 3 ? "underline" : "none",
                                    },
                                    on: {
                                        click: async () => {
                                            if (this.level == 3) {
                                                // await this.getList()
                                            }
                                        },
                                    },
                                },
                                params.row.blankColumnCommunityNum
                            )
                        },
                    },
                ]
            } else if (this.level == 5) {
                arr1 = [
                    {
                        title: "区",
                        key: "zoneName",
                        align: "center",
                        width: 200,
                    },
                    {
                        title: "街道",
                        key: "streetName",
                        align: "center",
                        width: 200,
                    },
                    {
                        title: "社区",
                        key: "communityName",
                        align: "center",
                        width: 200,
                    },
                    {
                        title: "辖区单位数",
                        key: "unitNum",
                        align: "center",
                        minWidth: 200,
                    },
                    {
                        title: "优质机构数",
                        key: "unitGoodNum",
                        align: "center",
                        minWidth: 200,
                    },
                    {
                        title: "优质机构名单",
                        key: "unitNames",
                        align: "center",
                        minWidth: 200,
                        render: (h, params) => {
                            return h(
                                "div",
                                {
                                    style: {
                                        color: "blue",
                                        cursor: "pointer",
                                        textDecoration: "underline",
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        display: '-webkit-box',
                                        WebkitLineClamp: '2',
                                        lineClamp:'2',
                                        WebkitBoxOrient:'vertical'

                                    },
                                    on: {
                                        click: async () => {
                                            this.getBlankList(params.row)
                                        },
                                    },
                                },
                                params.row.unitNames
                            )
                        },
                    },
                ]
            }
            this.talbeColumns = [...arr1, ...arr2]
            this.disabled = false
            this.loading = true
            let res = await this.$get("/gateway/api/symanage/pc/company/selectView", {
                orgCode: this.cascaderList && this.cascaderList.length ? this.cascaderList[this.cascaderList.length - 1] : "",
                onlineType: this.orgOnlineStatus,
                orgLevel: this.level,
            })
            if (res.code == 200) {
                this.loading = false
                this.tableData = res.dataList
                this.columnName = 0
                this.allTableData = this.tableData
                if (this.level == 5) {
                    this.total = this.tableData.length
                    this.tableData = JSON.parse(JSON.stringify(this.allTableData)).splice(0, 50)
                }
            } else {
                this.loading = false
                this.$Message.error({
                    content: res.decs,
                    background: true,
                })
            }
        },
        // 获取columnCode
        async getByMenuCode() {
            await this.$get("/old/api/pc/menu/selectMenuByMenuCode", {
                orgCode: parent.vue.loginInfo.userinfo.orgCode,
                menuCode: this.$core.getUrlParam("menuId"),
            }).then(res => {
                if (res.code == 200 && res.data) {
                    this.menuCodeObj = res.data
                }
            })
        },
        //导出报表
        Export() {
            this.$Modal.confirm({
                title: "温馨提示",
                content: "是否导出十五分钟生活圈栏目统计结果",
                onOk: () => {
                    this.$refs.tab.$refs.lftable.exportCsv({
                        filename: "十五分钟生活圈栏目统计结果",
                    })
                },
            })
        },
        // 导出内部报表
        exportColumn() {
            this.$Modal.confirm({
                title: "温馨提示",
                content: "是否导出优质机构",
                onOk: () => {
                    this.$refs.tabs.$refs.lftable.exportCsv({
                        filename: "优质机构",
                    })
                },
            })
        },

        // 获取空白栏目列表
        getBlankList(row) {
            this.blankStatus = true
            this.nameUrl = `广州市-${row.zoneName}-${row.streetName}-${row.communityName}`
            this.bTableData = []
            this.nowTime2 = this.$core.formatDate(new Date(new Date().getTime()), "yyyy-MM-dd")
            if (row.unitNames && row.unitNames != "") {
                this.bTableData = row.unitNames.split(",").map(item => {
                    return {
                        columnName: item,
                    }
                })
            }
            this.clumnNum = this.bTableData.length
        },
        changeBlankStatus(val) {
            this.blankStatus = val
        },
    },
}
</script>
    
<style scoped lang='less'>
/deep/#title {
    justify-content: space-between;
    display: flex;
    padding: 10px;
}
.readnum {
    display: inline;
    margin-left: 20px;
    font-size: 14px;
    font-weight: 700;
    li {
        display: inline;
        margin-left: 15px;
    }
    .click {
        text-decoration: underline;
        cursor: pointer;
    }
}
.readNum {
    margin-top: 5px;
    font-size: 14px;
    font-weight: 700;
    height: 20px;
    li {
        display: inline;
        margin-left: 15px;
    }
}
.ivu-form-item {
    margin-bottom: 5px;
    margin-right: 0px;
}
/deep/.ivu-table-sort {
    width: 26px;
    height: 13px;
    i {
        font-size: 27px;
    }
}
</style>